import React, { useState, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export function Rabit2(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF("/rabit-free-02.glb");
    const { actions, names } = useAnimations(animations, group);

    useEffect(() => {
        console.log(actions[names[0]])
        actions[names[0]].play()
    }, [actions, names])

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Sketchfab_Scene">
                <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
                    <group
                        name="aa4ce48898a049c68b395b4cf79dcbeefbx"
                        rotation={[Math.PI / 2, 0, 0]}
                        scale={0.01}
                    >
                        <group name="Object_2">
                            <group name="RootNode">
                                <group name="Object_4">
                                    <primitive object={nodes._rootJoint} />
                                    <group name="Object_6" rotation={[-Math.PI / 2, 0, 0]} />
                                    <group
                                        name="a251_body_hd_mesh"
                                        rotation={[-Math.PI / 2, 0, 0]}
                                    />
                                    <skinnedMesh
                                        name="Object_7"
                                        geometry={nodes.Object_7.geometry}
                                        material={materials.a251_body_hd_s0_c0}
                                        skeleton={nodes.Object_7.skeleton}
                                    />
                                </group>
                            </group>
                        </group>
                    </group>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/rabit-free-02.glb");