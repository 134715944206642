import './App.css';
import Gallery from './components/gallery/gallery'

import { useEffect } from 'react'

function App() {
  const title = document.getElementsByClassName("Title");
  const teacher = document.getElementsByClassName("Teacher");
  useEffect(() => {
    if(title[0] !== teacher[0] !== undefined && window.innerWidth > window.innerHeight){
      title[0].style.display = "none";
      teacher[0].style.display = "none";
    }
  })

  return (
    <div className="App">
      <div className="Title">
        <h1>Art Gallery</h1>
      </div>
      <Gallery />
      <div className="Teacher">
        <h2>Special thanks to Audrey<br/>Art studio Week-end à la mer</h2>
      </div>
    </div>
  );
}

export default App;
