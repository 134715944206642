import * as THREE from 'three'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useEffect, useRef, useState, Suspense } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, Text, Environment, Html, useProgress } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import getUuid from 'uuid-by-string';
import PropTypes from 'prop-types';
import './gallery.css';
import { Rabit1 } from './rabit1'
import { Rabit2 } from './rabit2'

const LONGSIDE = 1.4
const SHORTSIDE = 1


export default function Gallery() {
  const pexel = (id) => process.env.PUBLIC_URL + `/images/${id}.webp`

  const colorMap = useLoader(TextureLoader, 'beige_wall_001_rough_1k.jpg')

  const images = [
    // Front
    { position: [0, 0.6, 0], rotation: [0, 0, 0], url: pexel(1), width: LONGSIDE, height: SHORTSIDE },
    { position: [0, 2, 0], rotation: [0, 0, 0], url: pexel(4), width: LONGSIDE, height: SHORTSIDE },
    { position: [-1.8, 0.6, 0], rotation: [0, 0, 0], url: pexel(2), width: LONGSIDE, height: SHORTSIDE },
    { position: [-1.8, 2, 0], rotation: [0, 0, 0], url: pexel(7), width: LONGSIDE, height: SHORTSIDE },
    { position: [1.8, 0.6, 0], rotation: [0, 0, 0], url: pexel(6), width: LONGSIDE, height: SHORTSIDE },
    { position: [1.8, 2, 0], rotation: [0, 0, 0], url: pexel(10), width: LONGSIDE, height: SHORTSIDE },
    // Left
    { position: [-3.4, 1, 0], rotation: [0, 0, 0], url: pexel(5), width: SHORTSIDE, height: LONGSIDE },
    { position: [-4.8, 1, 0], rotation: [0, 0, 0], url: pexel(9), width: SHORTSIDE, height: LONGSIDE },
    // Right
    { position: [3.4, 1, 0], rotation: [0, 0, 0], url: pexel(8), width: SHORTSIDE, height: LONGSIDE },
    { position: [4.8, 1, 0], rotation: [0, 0, 0], url: pexel(3), width: SHORTSIDE, height: LONGSIDE }

  ]

  return (
    <Canvas gl={{ alpha: false }} dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 0, 15] }}>
      <Suspense fallback={<Loader />}>
        <ambientLight intensity={0.2} />
        <directionalLight position={[0, 0, 5]} color="white" />
        <color attach="background" args={['#191920']} />
        <fog attach="fog" args={['#191920', 10, 24]} />
        <Environment preset="city" />
        <Suspense fallback={null}>
          <Rabit1 position={[2, -1, 0]} scale={[4, 4, 4]} />
          <Rabit2 position={[0, -1, 0]} scale={[4, 4, 4]} />
        </Suspense>
        <group position={[0, -0.5, 0]}>
          <Frames images={images} />
          <mesh rotation={[0, 0, 0]} position={[0, 0, 0]}>
            <planeGeometry args={[50, 50]} />
            <meshStandardMaterial map={colorMap} resolution={1024} />
          </mesh>
          <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]}>
            <planeGeometry args={[50, 50]} />
            <MeshReflectorMaterial
              blur={[500, 100]}
              resolution={1024}
              mixBlur={1}
              mixStrength={40}
              roughness={1}
              depthScale={1.2}
              minDepthThreshold={0.4}
              maxDepthThreshold={1.4}
              color="#050505"
              metalness={0.5}
            />
          </mesh>
        </group>
      </Suspense>
    </Canvas >
  )
}

function Loader() {
  const { progress } = useProgress()
  return <Html center>{Math.floor(progress)} % loaded</Html>
}

function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const screensize = useRef();
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      screensize.zoom = window.innerWidth > 820 ? 1.25 : 4;
      screensize.main = window.innerWidth > 820 ? 4.5 : 18;
    } else {
      screensize.zoom = 3;
      screensize.main = window.innerWidth > 820 ? 8 : 5;
    }
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, LONGSIDE / 2, screensize.zoom))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, screensize.main)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    state.camera.position.lerp(p, 0.025)
    state.camera.quaternion.slerp(q, 0.025)
  })
  return (
    <group
      ref={ref}
      onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const [hovered, hover] = useState(false)
  const uuid = getUuid(url)
  const name = "Author:-S Kim"
  useCursor(hovered)
  const texture = useLoader(THREE.TextureLoader, url)
  const framesize = useRef();
  framesize.width = props.width > props.height ? 0.3 : -0.1;
  framesize.height = props.width > props.height ? 0.1 : -0.3;

  return (
    <group {...props}>
      <mesh
        name={uuid}
        scale={[props.width, props.height, 0.05]}
        position={[0, props.width / 2, 1.45]}>
        <boxGeometry args={[props.width - framesize.width, props.height + framesize.height, 1]} />
        <meshStandardMaterial color={"#191920"} toneMapped={false} fog={false} metalness={0.5} roughness={0.5} envMapIntensity={2} />
        <Text maxWidth={0.3} anchorX="left" anchorY="top" position={[-0.5, 0.72, 0]} fontSize={0.045}>
          {name.split('-').join('\n')}
        </Text>
      </mesh>
      <mesh
        name={uuid}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[props.width, props.height, 0.05]}
        position={[0, props.width / 2, 1.48]}>
        <planeGeometry />
        <meshStandardMaterial map={texture} />
      </mesh>
    </group >
  )
}

Gallery.propTypes = {};

Gallery.defaultProps = {};